<template>
  <div class="add-house-rule">
    <h1 class="admin-title">{{$t('New House Rules')}}</h1>
    <ValidationObserver ref="validator">
      <form @submit.prevent="save">

        <RFValidation rules="required" name="Name">
          <RFInput label="Name" v-model="houseRule.name"/>
        </RFValidation>

        <RFValidation rules="required" name="Translations">
          <RFInputTranslations label="Translations" @change="changeTranslations" v-model="houseRule.translations"/>
        </RFValidation>

        <RFValidation rules="required" name="Default Value">
          <RFSelect top-label="Default Value" v-model="houseRule.defaultValue" :value="houseRule.defaultValue" :list="defaultValueList"/>
        </RFValidation>

        <b-row class="justify-content-end">
          <b-col cols="auto">
            <RFButton class="mt-4" label="Save" background="#5CB85C"/>
          </b-col>
        </b-row>
      </form>
    </ValidationObserver>
  </div>
</template>

<script>
import RFInputTranslations from '@/components/forms/RFInputTranslations'
import RFInput from '@/components/forms/RFInput'
import RFSelect from '@/components/forms/RFSelect'
import RFButton from '@/components/forms/RFButton'
import RFValidation from '@/components/forms/RFValidation'

import { houseRuleService } from '@/services/admin'

export default {
  name: 'AdminAddHouseRule',
  components: {
    RFInputTranslations,
    RFInput,
    RFSelect,
    RFButton,
    RFValidation,
  },
  data() {
    return {
      houseRule: {
        name: null,
        defaultValue: null,
        translations: null,
      },
      defaultValueList: [],
    }
  },
  methods: {
    changeTranslations(translations) {
      this.houseRule.translations = translations
      this.defaultValueList = Object.values(this.houseRule.translations).map((value) => { return value ? { label: value, value } : {} })
      this.defaultValueList = this.defaultValueList.filter(defaultValue => Object.entries(defaultValue).length !== 0)
    },
    async save() {
      const isValid = await this.$refs.validator.validate()

      if (isValid) {
        this.loading = false
        const { status } = await houseRuleService.addHouseRule(this.houseRule)

        if (status === 200) {
          this.$toasted.success(this.$i18n.t(`House Rule correctly added!`))
          this.$router.push({ name: 'house-rule-list' })
        }

        this.loading = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
